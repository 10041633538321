<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			 return {
          data:{},
          prod:{},
          backLeft:{},
          backRight:{},
          settings:{},
          hilo:{}
       }
		},
		computed: {

		},
		async mounted() {
			this.$set(this.$root, 'page', this)
      this.getAbout()
			this.compScroll()
		},
		methods: {
      getAbout(){
        Gen.apirest("/about", {},(err,resp)=>{
          if(err) console.log("About goodness error "+err)
          this.data = resp.data
          this.prod = resp.product
          this.backLeft = resp.backLeft
          this.backRight = resp.backRight
          this.settings = resp.set
          this.hilo = resp.hilo
          setTimeout(()=>{
            AOS.init({ once: true });
          },500)
        })
      },
			compScroll(){
				var lastTop = 0;
				if ($(window).width() <= 991){
					var scroller = 5;
				}else{
					var scroller = 10;
				}
				$(window).scroll(function () {
					let st = $(this).scrollTop();
					if (st > lastTop) {
						$(".taste-comp.right").css("top", Number($(".taste-comp.right").css('top').replace('px',
							'')) - scroller + "px");
						$(".taste-comp.left").css("top", Number($(".taste-comp.left").css('top').replace('px',
							'')) + scroller + "px");
					} else {
						$(".taste-comp.right").css("top", Number($(".taste-comp.right").css('top').replace('px',
							'')) + scroller + "px");
						$(".taste-comp.left").css("top", Number($(".taste-comp.left").css('top').replace('px',
							'')) - scroller + "px");
					}
					lastTop = st;
				});
			}
		},
    watch: {
      $route() {
        console.log('hello')
      }
    },
	};
</script>
<template>
 <section id="content">
  <div class="content-wrap py-0">
    <section id="page-title" class="page-title-mini">
      <div class="container clearfix">
        <h1>{{web.menu_about}}</h1>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link :to="{name:'Index'}">{{web.menu_home}}</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{web.menu_about}}</li>
        </ol>
      </div>
    </section>
    <section class="section bg_blue_general lighter-wave pt-normal">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-11">
            <div class="about-product">
              <div class="heading-block center" data-aos="fade-up">
                <h2>{{hilo.as_name}}</h2>
              </div>
              <div class="about-product-meta" data-aos="fade-up" data-aos-delay="150" v-html="hilo.as_val">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section :class="settings.as_val=='N' ? 'section bg-blue' : 'section bg-blue light-wave'">
		<div class="taste-comp right" :style="'background-image: url('+uploader(backRight.as_val)+');'"></div>
		<div class="taste-comp left" :style="'background-image: url('+uploader(backLeft.as_val)+');'"></div>
      <div class="container">
        <div class="heading-block center" data-aos="fade-up">
          <h2>{{web.lbl_susu_hilo}}</h2>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-11">
            <div class="about-wrapper" v-for="(v,k) in data" :key="k">
              <div class="row">
                <div :class="k%2==0 ? 'col-md-4' : 'col-md-4  order-md-2'" data-aos="fade-up" :data-aos-delay="(k*150)">
                  <img :src="uploader(v.ag_img)" :alt="v.ag_title" class="about-wrapper__img">
                </div>
                <div class="col-md-8" data-aos="fade-up" :data-aos-delay="(k*150)">
                  <div class="about-wrapper-desc">
                    <h3>{{v.ag_title}}</h3>
                    <div v-html="v.ag_desc"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg_blue_general" v-if="settings.as_val=='Y'">
      <div class="container">
        <div class="heading-block center">
          <h2>{{web.lbl_product_hilo}}</h2>
        </div>
        <div class="row justify-content-center" v-if="prod.length">
          <div class="col-lg-7 col-md-10">
            <div class="row justify-content-center">
              <div class="col-md-4 col-6" data-aos="fade-up" v-for="(p,kp) in prod" :key="kp" :data-aos-delay="(kp*150)">
                <router-link :to="{name:'ProductCat',params:{cat:p.apc_slug}}" class="product-refer pad_10">
                  <img :src="uploader(p.apc_img_logo)" :alt="p.apc_name">
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
</template>